import { graphql, navigate } from 'gatsby';
// Utils
import { ENABLE_AMPLIFY_CHATBOT } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit & Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import ForgotNav from 'components/forgot-nav/forgot-nav';
import ForgotPasswordForm from 'components/forgot-password-form/forgot-password-form.component';
import { ForgotPasswordFormValues } from 'components/forgot-password-form/forgot-password-form.props';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import ResetPasswordForm, {
    ResetPasswordFailureModalContent,
    ResetPasswordSuccessModalContent
} from 'components/reset-password-form/reset-password-form.component';
import SecurityQuestionsForm, {
    SecurityQuestionFailureModalContent
} from 'components/security-questions-form/security-questions-form.component';
import { SecurityQuestionFormValues } from 'components/security-questions-form/security-questions-form.props';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { clearPasswordResetForm, logout } from 'state/account/account.reducers';
// Services
import {
    accountFetchSecurityQuestionsRoutine,
    accountResetPasswordRoutine,
    accountSubmitSecurityQuestionAnswerRoutine
} from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';
import { AccountSecurityAuth0Payload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { ResetPasswordFormValues } from 'types/forgot-password';

import { getPhoneNumber } from 'util/globalVariables';

import './forgot-password.styles.scss';

const ForgotPassword: React.FC<{ data: GatsbyTypes.ForgotPasswordDataQuery }> = ({ data }) => {
    const [hasSubmittedSecurityQuestion, setHasSubmittedSecurityQuestion] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        isFetchingSecurityQuestions,
        isSubmittingPasswordReset,
        isSubmittingSecurityQuestionAnswer,
        accountSecurity
    } = useSelector(accountStateSelector);

    const forgotPasswordStage = !hasSubmittedSecurityQuestion && (!accountSecurity || !accountSecurity.uid);
    const securityQuestionsStage = !hasSubmittedSecurityQuestion && accountSecurity && accountSecurity.uid;
    const resetPasswordStage = hasSubmittedSecurityQuestion;

    const showSendPasswordChangeSuccessModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        body={
                            <p
                                className="modal-body-content"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        t('modals.forgotPasswordSuccess.body', {
                                            phoneNumber: getPhoneNumber({})
                                        })
                                    )
                                }}
                            />
                        }
                        icon="default"
                        title={t('modals.forgotPasswordSuccess.title')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ForgotPasswordSuccess',
                        label: t('modals.forgotPasswordSuccess.signIn'),
                        onClick: () => {
                            dispatch(closeModal({}));
                            navigate('/sign-in');
                        },
                        variant: 'primary'
                    }
                ],
                showClose: true
            })
        );
    }, [dispatch, t]);

    const showSendPasswordChangeErrorModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: <BirdiModalContentAlt subTitle={t('modals.forgotPasswordFailure.body')} />,
                ctas: [
                    {
                        dataGALocation: 'ForgotPasswordError',
                        label: t('modals.forgotPasswordFailure.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.forgotPasswordFailure.title')} icon="alert" />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    }, [dispatch, t]);

    const navigationHandler = () => {
        if (securityQuestionsStage) {
            dispatch(clearPasswordResetForm());
        }
        if (resetPasswordStage) {
            setHasSubmittedSecurityQuestion(false);
        }
    };

    const handleSubmitForgotPasswordForm = ({ username }: ForgotPasswordFormValues) => {
        dispatch(
            accountFetchSecurityQuestionsRoutine.trigger({
                username,
                onSuccess: ({ Success, uid }: AccountSecurityAuth0Payload) => {
                    /*
                     * The modal will be shown if the user is Auth0 authenticated,
                     * or if the user is not found from the non-migrated base.
                     */
                    if (Success === true || !uid) {
                        showSendPasswordChangeSuccessModal();
                    }
                },
                onFailure: () => {
                    showSendPasswordChangeErrorModal();
                }
            })
        );
    };

    const handleSubmitSecurityQuestionForm = ({ answer, question, questionKey }: SecurityQuestionFormValues) => {
        if (!question || !questionKey) return;

        dispatch(
            accountSubmitSecurityQuestionAnswerRoutine.trigger({
                accountSecurity: {
                    ...accountSecurity,
                    [questionKey]: answer
                },
                onSuccess: () => {
                    //  render the password reset components
                    setHasSubmittedSecurityQuestion(true);
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <SecurityQuestionFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.securityQuestionFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ForgotPasswordSecurityQuestionError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleSubmitResetPasswordForm = (values: ResetPasswordFormValues) => {
        dispatch(
            accountResetPasswordRoutine.trigger({
                accountSecurity: {
                    ...accountSecurity,
                    newPassword: values.password,
                    shortToken: values.resetToken
                },
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: false,
                            onClose: () => navigate('/sign-in'),
                            bodyContent: <ResetPasswordSuccessModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.resetPasswordSuccess.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        navigate('/sign-in');
                                    },
                                    dataGALocation: 'ResetPasswordSuccess'
                                }
                            ]
                        })
                    );
                },
                onFailure: (response: string) => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <ResetPasswordFailureModalContent translation={t} error={response} />,
                            ctas: [
                                {
                                    label: t('modals.resetPasswordFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ResetPasswordError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    useEffect(() => {
        return () => {
            if (!ENABLE_AMPLIFY_CHATBOT) {
                dispatch(logout());
            }
        };
    }, [dispatch]);

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('forgotPassword.labels.title') }}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'login'}
                eyebrowText={t('forgotPassword.labels.eyebrowText')}
                nav={<ForgotNav onBackButtonClick={navigationHandler} />}
                title={t('forgotPassword.labels.title')}
            >
                <>
                    {forgotPasswordStage && (
                        <ForgotPasswordForm
                            onSubmit={handleSubmitForgotPasswordForm}
                            isFetchingSecurityQuestions={isFetchingSecurityQuestions}
                        />
                    )}
                    {securityQuestionsStage && (
                        <SecurityQuestionsForm
                            onSubmit={handleSubmitSecurityQuestionForm}
                            accountSecurity={accountSecurity}
                            isSubmittingSecurityQuestionAnswer={isSubmittingSecurityQuestionAnswer}
                        />
                    )}
                    {resetPasswordStage && (
                        <ResetPasswordForm
                            onSubmit={handleSubmitResetPasswordForm}
                            isSubmittingPasswordReset={isSubmittingPasswordReset}
                        />
                    )}
                </>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ForgotPassword;

export const query = graphql`
    query ForgotPasswordData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
